import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Quiz } from '../../../models/Quiz';

interface IProps extends RouteComponentProps {
  quiz: Quiz;
}

export const Deploy: React.FC<IProps> = () => <React.Fragment>Deploy</React.Fragment>;
