import * as React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { CampaignsList } from './List';
import { Show } from './Show';

export const Campaigns: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={CampaignsList} />
      <Route path={`${match.url}/:id`} component={Show} />
    </Switch>
  );
};
