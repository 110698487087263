import { TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';
import { orderType, columns } from './List';
import * as React from 'react';
// function desc(a: Quiz, b: Quiz, orderBy: any) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }
// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }
// function getSorting(order: orderType, orderBy: string) {
//   return order === "desc"
//     ? (a: any, b: any) => desc(a, b, orderBy)
//     : (a: any, b: any) => -desc(a, b, orderBy);
// }
interface IEnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<HTMLElement>, property: string) => void;
  order: orderType;
  orderBy: string;
}
export class EnhancedTableHead extends React.Component<IEnhancedTableHeadProps> {
  createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => {
    this.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding="default"
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}
