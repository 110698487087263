import { ListItem } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps extends ListItemProps {
  to: LocationDescriptor;
}
export class ListItemLink extends React.Component<IProps> {
  renderLink = (itemProps: any) => <Link to={this.props.to} {...itemProps} />; //eslint-disable-line

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <li className={className}>
        <ListItem
          button
          component={this.renderLink}
          {...rest}
          selected={window.location.pathname === this.props.to}
        >
          {children}
        </ListItem>
      </li>
    );
  }
}
