import { IconButton, Typography } from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { PureComponent } from 'react';
import { AnswerForm } from './AnswerForm';
import {
  IAnswerItemProps,
  AnswerListItem,
  LinkBlock,
  AnswerNumber,
  CollapseContainer,
  StyledCollapse,
  AnswerButtonsContainer,
  ExpansionButton
} from './QuestionEdit';

export class AnswerItem extends PureComponent<IAnswerItemProps> {
  render() {
    const {
      answer,
      onClickToggleAnswer,
      onClickDeleteAnswer,
      answerIndex,
      open,
      quiz,
      focused,
      refreshQuiz
    } = this.props;
    return (
      <AnswerListItem>
        {!open && <LinkBlock onClick={onClickToggleAnswer(answer.id)} />}

        <AnswerNumber variant="body2">{answerIndex + 1}. </AnswerNumber>
        <CollapseContainer>
          <StyledCollapse in={!open}>
            <Typography variant="body1">{answer.text}</Typography>
            <Typography variant="body2">
              {answer.offer && `Outcome: ${answer.offer.outcome.text}`}
            </Typography>
          </StyledCollapse>
          <StyledCollapse in={open} unmountOnExit>
            <AnswerForm
              refreshQuiz={refreshQuiz}
              quizFormat={quiz.format}
              answer={answer}
              outcomes={quiz.offers}
              focused={focused}
            />
          </StyledCollapse>
        </CollapseContainer>
        <AnswerButtonsContainer>
          <ExpansionButton onClick={onClickToggleAnswer(answer.id)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ExpansionButton>
          {open && (
            <IconButton onClick={onClickDeleteAnswer(answer)} title="Delete this answer">
              <Delete />
            </IconButton>
          )}
        </AnswerButtonsContainer>
      </AnswerListItem>
    );
  }
}
