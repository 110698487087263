import { Button, FormHelperText, Typography, withTheme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { TypographyProps } from '@material-ui/core/Typography';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { AuthLayout } from '../Layouts/AuthLayout';

export interface ILogInFormValues {
  email: string;
  password: string;
}

const SubButton = styled(Button)`
  display: flex;
  margin: ${appTheme.spacing.unit}px auto;
` as React.FC<ButtonProps>;

const MyLink = (props: any) => <Link to="/signup" {...props} />; //eslint-disable-line
const createAnAccount = <SubButton component={MyLink}>Create an account</SubButton>;

const LogInForm = ({ isSubmitting, status }: FormikProps<ILogInFormValues>) => (
  <AuthLayout bottomElement={createAnAccount}>
    <LogInTitle variant="h4">Log In</LogInTitle>
    <Form>
      <Field
        component={TextField}
        type="email"
        name="email"
        variant="outlined"
        label="email"
        fullWidth
        margin="normal"
        required
      />
      <Field
        component={TextField}
        type="password"
        name="password"
        variant="outlined"
        label="password"
        fullWidth
        margin="normal"
        required
      />
      {status && <FormHelperText>{status}</FormHelperText>}
      <LogInButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
        Log In
      </LogInButton>
    </Form>
  </AuthLayout>
);

const LogInTitle = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 3}px;
` as React.FC<TypographyProps>;

const LogInButton = styled(Button)`
  margin: ${appTheme.spacing.unit * 3}px auto 0;
  width: 75%;
  display: flex;
` as React.FC<ButtonProps>;

const themed = withTheme()(LogInForm);
export { themed as LogInForm };
