import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { QuizContext } from '../../../contexts/QuizContext';
import { Answer } from '../../../models/Answer';
import { Question } from '../../../models/Question';
import { Quiz } from '../../../models/Quiz';
import { CampaignStepper } from './CampaignStepper';

interface IState {
  quiz?: Quiz;
}
export class Show extends React.Component<RouteComponentProps<{ id: string }>, IState> {
  state: IState = {
    quiz: undefined
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.fetchQuiz(id);
  }

  async fetchQuiz(id: string) {
    try {
      const answerScope = Answer.order({ position: 'asc' });
      const questionScope = Question.order({ position: 'asc' });

      const { data: quiz } = await Quiz.includes(['offers', { questions: { answers: 'offer' } }])
        .merge({ questions: questionScope, answers: answerScope })
        .find(id);
      this.setState({ quiz });
    } catch (err) {
      this.props.history.goBack();
    }
  }

  refreshQuiz = () => {
    this.setState(prevState => {
      return {
        quiz: prevState.quiz
      };
    });
  };

  getOutcomes = () => {
    const { quiz } = this.state;
    if (quiz) {
      return quiz.offers;
    } else {
      return [];
    }
  };

  render() {
    const { quiz } = this.state;
    const { match } = this.props;
    return quiz ? (
      <React.Fragment>
        <QuizContext.Provider
          value={{ refreshQuiz: this.refreshQuiz, getOutcomes: this.getOutcomes, quiz }}
        >
          <Route
            exact
            path={match.url}
            render={props => {
              if (quiz.format) {
                return <Redirect to={`${match.url}/build`} />;
              } else {
                return <Redirect to={`${match.url}/settings`} />;
              }
            }}
          />
          <Route
            path={`${match.url}/:action`}
            render={props => <CampaignStepper {...props} quiz={quiz} />}
          />
        </QuizContext.Provider>
      </React.Fragment>
    ) : null;
  }
}
