import { Formik } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import { auth0Client } from '../../../lib/Auth';
import { User } from '../../../models/User';
import { ISignUpFormValues, SignUpForm } from './SignUpForm';

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short')
    .required('Required'),
  company: Yup.string()
    .min(2, 'Too Short')
    .notRequired(),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required')
    .min(6, 'Must be at least 6 characters')
});

export class SignUp extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <div>
        <Formik
          initialValues={{
            email: '',
            password: '',
            company: '',
            name: ''
          }}
          validationSchema={SignUpSchema}
          onSubmit={(values: ISignUpFormValues, { setSubmitting, setStatus }) => {
            const { email, password, name, company } = values;

            auth0Client
              .signUp({
                email,
                password
              })
              .then(redirectTo => {
                const user = new User({ company, name });
                user.save();
                this.props.history.replace(redirectTo);
              })
              .catch((error: { description: string }) => {
                setSubmitting(false);
                setStatus(error.description);
              });
          }}
          component={SignUpForm}
        />
      </div>
    );
  }
}
