import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import * as React from 'react';

interface IProps {
  title: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  content?: string;
}

export class ConfirmationDialog extends React.Component<IProps> {
  state = {
    open: false
  };

  handleConfirm = () => {
    this.props.onConfirm();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const {
      open,
      title,
      cancelText = 'Cancel',
      confirmText = 'OK',
      content,
      onCancel
    } = this.props;

    return (
      <div>
        <Dialog open={open} onClose={onCancel}>
          <DialogTitle>{title}</DialogTitle>
          {content && (
            <DialogContent>
              <DialogContentText>{content}</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleCancel}>{cancelText}</Button>
            <Button onClick={this.handleConfirm}>{confirmText}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
