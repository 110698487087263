import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Typography
} from '@material-ui/core';
import { CollapseProps } from '@material-ui/core/Collapse';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { ListItemProps } from '@material-ui/core/ListItem/ListItem';
import { ListSubheaderProps } from '@material-ui/core/ListSubheader';
import { TypographyProps } from '@material-ui/core/Typography';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { QuizContext } from '../../../../contexts/QuizContext';
import { extendedTheme } from '../../../../lib/theme';
import { Answer, Question, Quiz } from '../../../../models';
import { AutoSave } from '../../../atoms/AutoSave';
import { ConfirmationDialog } from '../../../atoms/ConfirmationDialog';
import { ImagePicker } from '../../../atoms/ImagePicker';
import { AnswerItem } from './AnswerItem';
import { Delete } from '@material-ui/icons';

export interface IProps extends RouteComponentProps<{ questionId: string }> {
  question: Question;
  refreshQuiz: () => void;
}

interface IQuestionFormProps {
  prompt: string;
  position: number;
  image: { original: string };
}

interface IState {
  openAnswerIds: Set<string>;
  focusedAnswer?: string;
  answerToDelete?: Answer;
  showDeleteQuestionDialog: boolean;
}

export class QuestionEdit extends React.Component<IProps, IState> {
  state: IState = {
    openAnswerIds: new Set<string>(),
    showDeleteQuestionDialog: false
  };

  addingAnswer: boolean = false;

  toggleAnswer = (answerId: string) => {
    this.setState(prevState => {
      const { openAnswerIds } = prevState;
      let { focusedAnswer } = prevState;
      if (!openAnswerIds.delete(answerId)) {
        openAnswerIds.add(answerId);
        focusedAnswer = answerId;
      }
      return { openAnswerIds, focusedAnswer };
    });
  };

  saveQuestion = async (props: IQuestionFormProps) => {
    const { question, refreshQuiz } = this.props;
    question.assignAttributes(props);
    await question.save();
    refreshQuiz();
  };

  addAnswer = async () => {
    const { refreshQuiz } = this.props;
    if (this.addingAnswer) {
      return;
    }
    this.addingAnswer = true;
    const { question } = this.props;
    const answer = new Answer({ position: question.answers.length + 1 });
    question.answers.push(answer);
    await question.save({ with: 'answers' });
    this.toggleAnswer(answer.id);
    this.addingAnswer = false;
    refreshQuiz();
  };

  handleClickToggleAnswer = (answerId: string) => () => {
    this.toggleAnswer(answerId);
  };

  handleClickExpandAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const {
      question: { answers }
    } = this.props;
    const openAnswerIds = answers.reduce((acc, answer) => {
      return acc.add(answer.id);
    }, new Set<string>());
    this.setState({ openAnswerIds });
  };

  handleDeleteQuestion = () => {
    this.setState({ showDeleteQuestionDialog: true });
  };

  handleConfirmDeleteQuestion = async () => {
    const { question, history } = this.props;
    await question.destroy();
    history.replace(`/campaigns/`);
  };

  handleDeleteAnswer = (answer: Answer) => () => {
    this.setState({ answerToDelete: answer });
  };

  handleCancelDeleteQuestion = () => {
    this.setState({ showDeleteQuestionDialog: false });
  };

  handleConfirmDeleteAnswer = async () => {
    const { answerToDelete } = this.state;
    const { refreshQuiz, question } = this.props;
    if (answerToDelete) {
      const answer = question.answers.find(answerItem => answerItem.id === answerToDelete.id);
      answer && (await answer.destroy());
    }
    this.setState({ answerToDelete: undefined });
    refreshQuiz();
  };

  handleCancelDeleteAnswer = () => {
    this.setState({ answerToDelete: undefined });
  };

  public render() {
    const { question } = this.props;
    const { answerToDelete, openAnswerIds, showDeleteQuestionDialog } = this.state;

    return (
      <React.Fragment>
        {answerToDelete && (
          <ConfirmationDialog
            open
            title="Delete this answer?"
            content={answerToDelete.text}
            onConfirm={this.handleConfirmDeleteAnswer}
            onCancel={this.handleCancelDeleteAnswer}
          />
        )}
        {showDeleteQuestionDialog && (
          <ConfirmationDialog
            open
            title="Delete this question?"
            content={question.prompt}
            onConfirm={this.handleConfirmDeleteQuestion}
            onCancel={this.handleCancelDeleteQuestion}
          />
        )}
        <Formik<IQuestionFormProps>
          initialValues={question.attributes as IQuestionFormProps}
          onSubmit={() => {}}
          render={({ values, setFieldValue }) => (
            <form onSubmit={event => event.preventDefault()}>
              <Field
                component={TextField}
                name="prompt"
                variant="outlined"
                type="text"
                label="Prompt"
                margin="normal"
                fullWidth
              />
              <Field type="hidden" name="image.original" />
              <ImagePicker
                imageUrl={values.image.original}
                imagePreviewOptions={{ width: 200, height: 200 }}
                onSuccess={response => {
                  setFieldValue('image.original', response.url);
                }}
              />
              <AutoSave values={values} onSubmit={this.saveQuestion} />
            </form>
          )}
        />
        <List
          subheader={
            <StyledListSubHeader component="div">
              <span>Answers</span>
              <Button variant="text" onClick={this.handleClickExpandAll}>
                expand all
              </Button>
            </StyledListSubHeader>
          }
        >
          <QuizContext.Consumer>
            {({ quiz, refreshQuiz }) => {
              return question.answers.map((answer, answerIndex) => {
                const open = openAnswerIds.has(answer.id);
                return (
                  <AnswerItem
                    key={`answer-form-${answer.id}`}
                    answer={answer}
                    onClickToggleAnswer={this.handleClickToggleAnswer}
                    answerIndex={answerIndex}
                    open={open}
                    focused={answer.id === this.state.focusedAnswer}
                    quiz={quiz}
                    refreshQuiz={refreshQuiz}
                    onClickDeleteAnswer={this.handleDeleteAnswer}
                  />
                );
              });
            }}
          </QuizContext.Consumer>
        </List>
        <Buttons>
          <Button variant="text" onClick={this.addAnswer}>
            Add Answer
          </Button>
          <IconButton onClick={this.handleDeleteQuestion} title="Delete this question">
            <Delete />
          </IconButton>
        </Buttons>
      </React.Fragment>
    );
  }
}

export interface IAnswerItemProps {
  answer: Answer;
  onClickToggleAnswer: (id: string) => (event: React.MouseEvent<HTMLAnchorElement>) => void;
  answerIndex: number;
  open: boolean;
  quiz: Quiz;
  focused: boolean;
  refreshQuiz: () => void;
  onClickDeleteAnswer: (answer: Answer) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledListSubHeader = styled(ListSubheader)`
  display: flex;
  justify-content: space-between;
` as React.FC<ListSubheaderProps>;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkBlock = styled.a`
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
`;

export const AnswerButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
`;

export const AnswerNumber = styled(Typography)`
  width: 1.5em;
` as React.FC<TypographyProps>;

export const AnswerListItem = styled(ListItem)`
  cursor: pointer;
  align-items: start;
  border: 1px solid ${extendedTheme.borderColor};
  border-top: 0;
  &:first-of-type {
    border-top: 1px solid ${extendedTheme.borderColor};
  }
` as React.FC<ListItemProps>;

export const ExpansionButton = styled(IconButton)`` as React.FC<IconButtonProps>;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCollapse = styled(Collapse)`
  flex-grow: 1;
` as React.FC<CollapseProps>;
