import auth0, { Auth0DecodedHash, Auth0UserProfile, WebAuth } from 'auth0-js';

const { REACT_APP_AUTH0_DOMAIN = '', REACT_APP_AUTH0_CLIENT_ID = '' } = process.env;

export interface IUserProfile extends Auth0UserProfile {
  isAdmin: boolean;
}

class Auth {
  profile: IUserProfile | null;
  auth0: WebAuth;
  expiresAt?: number;
  idToken?: string;
  accessToken: string;

  constructor() {
    this.auth0 = new WebAuth({
      // the following three lines MUST be updated
      domain: REACT_APP_AUTH0_DOMAIN,
      audience: `https://${REACT_APP_AUTH0_DOMAIN}/userinfo`,
      clientID: REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: `${window.location.origin}/auth/callback`,
      responseType: 'id_token',
      scope: 'openid profile'
    });

    const existingToken = localStorage.getItem('FI_TOKEN');
    if (existingToken) {
      this.idToken = existingToken;
    }

    const existingExpiresAt = localStorage.getItem('FI_EXPIRES_AT');
    if (existingExpiresAt) {
      this.expiresAt = parseInt(existingExpiresAt);
    }

    this.getProfile = this.getProfile.bind(this);
    this.handleCallbackAuthentication = this.handleCallbackAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    if (this.expiresAt && new Date().getTime() < this.expiresAt) {
      return true;
    } else {
      this.signOut();
      return false;
    }
  }

  signIn({
    email,
    password,
    state = {}
  }: {
    email: string;
    password: string;
    state: { from?: string };
  }) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password: password,
          audience: `api.furtherinsights.com`,
          scope: 'openid email profile'
        },
        (err, authResult) => {
          if (err) {
            reject(err);
          } else {
            this.processAuthResult(authResult);

            resolve(state.from || '/');
          }
        }
      );
    });
    // this.auth0.authorize({ state, prompt: "none" });
  }

  signUp({ email, password }: { email: string; password: string }) {
    return new Promise((resolve, reject) => {
      this.auth0.signupAndAuthorize(
        { connection: 'Username-Password-Authentication', email, password },
        (err, authResult) => {
          if (err) {
            reject(err);
          } else {
            this.processAuthResult(authResult);
            resolve('/');
          }
        }
      );
    });
  }

  processAuthResult(authResult: Auth0DecodedHash) {
    this.idToken = authResult.idToken;
    this.expiresAt = authResult.expiresIn && +new Date() + authResult.expiresIn * 1000;

    localStorage.setItem('FI_TOKEN', this.idToken as string);

    this.expiresAt && localStorage.setItem('FI_EXPIRES_AT', this.expiresAt.toString());

    let redirectTo = '/campaigns';

    return redirectTo;
  }

  handleCallbackAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        localStorage.setItem('FI_TOKEN', authResult.idToken as string);

        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        localStorage.setItem('FI_EXPIRES_AT', this.expiresAt.toString());
        const state = authResult.state;
        let redirectTo = '/campaigns';
        if (state) {
          const oldState = JSON.parse(localStorage[state]);
          localStorage.removeItem(state);
          redirectTo = oldState.redirectAfterAuth;
        }
        resolve(redirectTo);
      });
    });
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = undefined;
    this.profile = null;
    this.expiresAt = undefined;
    localStorage.removeItem('FI_TOKEN');
    localStorage.removeItem('FI_EXPIRES_AT');
  }
}

export const auth0Client = new Auth();
