import React from 'react';
import { isEqual, debounce } from 'lodash';

interface IProps<FormProps> {
  values: FormProps;
  debounce?: number;
  onSubmit: (props: FormProps) => Promise<void>;
  render?: (state: IState) => {};
}

interface IState {
  isSaving: boolean;
}

export class AutoSave<FormProps> extends React.Component<IProps<FormProps>, IState> {
  state = {
    isSaving: false
  };

  changed = debounce(props => {
    this.props.onSubmit(props);
  }, this.props.debounce || 300);

  componentDidUpdate(prevProps: IProps<FormProps>) {
    const { values } = this.props;

    if (!isEqual(values, prevProps.values)) {
      this.setState({ isSaving: true });
      this.changed(values);
    }
  }

  render() {
    return null; //this.props.render(this.state);
  }
}
