import React, { Component, Fragment } from 'react';

import { Header } from '../../organisms/Header';
import { Sidebar } from '../../organisms/Sidebar';
import styled from '../../../lib/styled-components';
import { withTheme } from '@material-ui/core';

interface IState {
  drawerIsOpen: boolean;
}

export class Layout extends Component<{}, IState> {
  state = {
    drawerIsOpen: false
  };

  handleToggleDrawer = (open: boolean) => {
    this.setState({ drawerIsOpen: open });
  };

  render() {
    return (
      <Fragment>
        <div style={{ display: 'flex' }}>
          <Header drawerIsOpen={this.state.drawerIsOpen} onToggleDrawer={this.handleToggleDrawer} />
          <Sidebar
            drawerIsOpen={this.state.drawerIsOpen}
            onToggleDrawer={this.handleToggleDrawer}
          />
          <Content>
            <AppBarSpacer />
            {this.props.children}
          </Content>
        </div>
      </Fragment>
    );
  }
}

const AppBarSpacer = withTheme()(styled.div`
  ${props => props.theme.mixins.toolbar}
`);

const Content = withTheme()(styled.main`
  flex-grow: 1;
  padding: ${props => props.theme.spacing.unit * 3}px;
  height: 100vh;
  overflow: auto;
`);
