import * as React from 'react';
import { Quiz } from '../../../../models';
import * as Sentry from '@sentry/browser';
import { EQuizFormat } from '../../../../models/Quiz';

const { REACT_APP_QUIZ_EMBED_URL = '' } = process.env;

declare global {
  //eslint-disable-next-line
  interface Window {
    QuizPreview: (quiz: Quiz) => object;
  }
}

function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    if (window.QuizPreview === undefined) {
      const script = document.createElement('script');
      script.src = src;
      script.setAttribute('data-processed', 'true');
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', error => {
        reject(error);
      });
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}

interface IProps {
  quiz: Quiz;
  path: string;
  selectedResultId: string;
}

interface IState {
  status: 'start' | 'done' | 'error';
  loadAttempts: number;
}

export class QuizPreview extends React.Component<IProps, IState> {
  quizPreview: any; //eslint-disable-line

  state: IState = {
    status: 'start',
    loadAttempts: 1
  };

  loadQuiz = () => {
    const { quiz, path, selectedResultId } = this.props;
    loadScript(REACT_APP_QUIZ_EMBED_URL)
      .then(() => {
        this.quizPreview = window.QuizPreview(quiz);
        this.setState({ status: 'done' });
        this.quizPreview.props.history.replace(path);
        if (path === 'result') {
          this.quizPreview.props.store.dispatch({
            type: 'FORCE_RESULT',
            payload: getResultState(quiz, selectedResultId)
          });
        }
      })
      .catch(e => {
        if (this.state.loadAttempts > 5) {
          Sentry.captureException(e);
        } else {
          this.setState(prevState => ({
            status: 'error',
            loadAttempts: prevState.loadAttempts + 1
          }));
        }
      });
  };

  componentDidUpdate() {
    const { quiz, path, selectedResultId } = this.props;

    if (this.state.status === 'done') {
      this.quizPreview.props.store.dispatch({ type: 'update_quiz', quiz });
      this.quizPreview.props.history.replace(path);
      if (path === 'result') {
        this.quizPreview.props.store.dispatch({
          type: 'FORCE_RESULT',
          payload: getResultState(quiz, selectedResultId)
        });
      }
    } else if (this.state.status === 'error') {
      const { loadAttempts } = this.state;
      if (loadAttempts <= 5) {
        setTimeout(this.loadQuiz, 2000 * loadAttempts);
      }
    }
  }

  componentDidMount() {
    if (this.state.status === 'start') {
      setTimeout(() => this.loadQuiz(), 0);
    }
  }

  render() {
    let quizElement = '';
    if (this.state.status === 'done') {
      quizElement = this.quizPreview;
    }

    return <div>{quizElement}</div>;
  }
}

function getResultState(quiz: Quiz, selectedResultId: string) {
  const offer = quiz.offers.find(offer => offer.id === selectedResultId);
  if (!offer) {
    return {};
  }
  let score: number, answersCount: number;
  if (quiz.format === EQuizFormat.PersonalityQuiz) {
    score = 1;
    answersCount = 1;
    return {
      outcomes: {
        [offer.id]: {
          score,
          answersCount
        }
      }
    };
  } else {
    answersCount = quiz.questions.length;
    score = answersCount * (offer.range.min / 100);

    return {
      correctSelectedAnswersNum: Math.ceil(score)
    };
  }
}
