import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { PaperProps } from '@material-ui/core/Paper';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { Quiz, EQuizSortAttributes } from '../../../models/Quiz';
import { EnhancedTableHead } from './EnhancedTableHead';
import { TableRowProps } from '@material-ui/core/TableRow';
import { formatDistance } from 'date-fns';
import { auth0Client } from '../../../lib/Auth';

export type orderType = 'asc' | 'desc';

export const columns = [
  { id: 'title', numeric: false, label: 'Campaign' },
  { id: 'strategy', numeric: true, label: 'Strategy' },
  { id: 'views', numeric: true, label: 'Views' },
  { id: 'completions', numeric: true, label: 'Completions' },
  { id: 'updatedAt', numeric: true, label: 'Last Updated' }
];

interface ICampaignsListState {
  quizzes: Quiz[];
  order: orderType;
  orderBy: EQuizSortAttributes;
  page: number;
  rowsPerPage: number;
}

export class CampaignsList extends React.Component<RouteComponentProps, ICampaignsListState> {
  state: ICampaignsListState = {
    quizzes: [],
    order: 'desc' as orderType,
    orderBy: EQuizSortAttributes.UpdatedAt,
    page: 0,
    rowsPerPage: 10
  };

  componentDidMount() {
    this.fetchQuizzes().then(quizzes => this.setState({ quizzes }));
  }

  private async fetchQuizzes() {
    const { data: quizzes } = await Quiz.select({
      quizzes: ['title', 'updated_at', 'notes', 'id', 'user'],
      user: ['email']
    })
      .includes('user')
      .order({ updated_at: 'desc' }) //eslint-disable-line
      .all();
    return quizzes;
  }

  compareStrings(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  sortedQuizzes = (): Quiz[] => {
    const { quizzes, order, orderBy } = this.state;
    quizzes.sort(
      (quizA, quizB): number => {
        const varA =
          typeof quizA[orderBy] === 'string'
            ? quizA[orderBy].toString().toUpperCase()
            : quizA[orderBy];
        const varB =
          typeof quizB[orderBy] === 'string'
            ? quizB[orderBy].toString().toUpperCase()
            : quizB[orderBy];
        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      }
    );

    return quizzes;
  };

  handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
    event.stopPropagation();
    const orderBy = property as EQuizSortAttributes;
    let order = 'desc' as orderType;

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({ page });
  };

  handleNewCampaign = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const quiz = new Quiz();
    await quiz.save();
    this.props.history.push(`/campaigns/${quiz.id}/settings`);
  };

  public render() {
    const { quizzes, order, orderBy, page, rowsPerPage } = this.state;
    const { match } = this.props;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, quizzes.length - page * rowsPerPage);

    return (
      <React.Fragment>
        <h1>Campaigns</h1>
        <StyledPaper>
          <NewCampaignButton variant="contained" onClick={this.handleNewCampaign}>
            New Campaign
          </NewCampaignButton>
          <Table padding="none">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {this.sortedQuizzes()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(quiz => {
                  return (
                    <StyledTableRow hover key={`quiz-list-${quiz.id}`}>
                      <TableCell component="th" scope="row">
                        <Link to={`${match.url}/${quiz.id}`}>
                          <QuizTitle>{quiz.title}</QuizTitle>
                          {quiz.notes && ` - ${quiz.notes}`}
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`${match.url}/${quiz.id}`} />
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`${match.url}/${quiz.id}`} />
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`${match.url}/${quiz.id}`} />
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`${match.url}/${quiz.id}`}>{timeAgo(quiz.updatedAt)}</Link>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {quizzes && (
            <TablePagination
              component="div"
              count={quizzes.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={this.handleChangePage}
            />
          )}
        </StyledPaper>
      </React.Fragment>
    );
  }
}

const timeAgo = (date: string): string => {
  return `${formatDistance(new Date(date), new Date())} ago`;
};

const StyledTableRow = styled(TableRow)`
  td > a,
  th > a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: ${appTheme.spacing.unit * 2}px ${appTheme.spacing.unit * 3}px;
  }
` as React.FC<TableRowProps>;

const QuizTitle = styled.span`
  font-weight: bold;
`;

const StyledPaper = styled(Paper)`
  position: relative;
` as React.FC<PaperProps>;

const NewCampaignButton = styled(Button)`
  position: absolute;
  right: ${appTheme.spacing.unit * 5}px;
  top: -${appTheme.spacing.unit * 6}px;
` as React.FC<ButtonProps>;
