import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { ListItemProps } from '@material-ui/core/ListItem/ListItem';
import React from 'react';
import { LinkProps } from 'react-router-dom';

import styled from '../../../../../lib/styled-components';
import { extendedTheme } from '../../../../../lib/theme';
import { ListItemLink } from '../../../../atoms/ListItemLink';

export const StyledListItemLink = styled(ListItemLink)`
  border-bottom: 1px solid ${extendedTheme.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
` as React.FC<ListItemProps & LinkProps & ButtonBaseProps>;
