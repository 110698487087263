import { createContext } from "react";

import { Offer, Quiz } from "../models";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const DEFAULT_STATE: IQuizContext = {
  refreshQuiz: () => {},
  getOutcomes: () => [],
  quiz: new Quiz()
};

interface IQuizContext {
  refreshQuiz: () => void;
  getOutcomes: () => Offer[];
  quiz: Quiz;
}

export const QuizContext = createContext<IQuizContext>(DEFAULT_STATE);
