import { Formik } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { auth0Client } from '../../../lib/Auth';
import { ILogInFormValues, LogInForm } from './LogInForm';

export interface ILogInProps extends RouteComponentProps {}

export interface ILogInState {}

export class LogIn extends React.Component<ILogInProps, ILogInState> {
  public render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values: ILogInFormValues, { setSubmitting, setStatus }) => {
            auth0Client
              .signIn({
                email: values.email,
                password: values.password,
                state: this.props.location.state
              })
              .then(redirectTo => {
                this.props.history.replace(redirectTo);
              })
              .catch((error: any) => {
                setSubmitting(false);
                setStatus(error.description);
              });
          }}
          component={LogInForm}
        />
      </React.Fragment>
    );
  }
}
