import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withTheme
} from '@material-ui/core';
import { AppBarProps } from '@material-ui/core/AppBar';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { ToolbarProps } from '@material-ui/core/Toolbar';
import { TypographyProps } from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Component, Fragment } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { auth0Client } from '../../lib/Auth';
import styled, { css } from '../../lib/styled-components';
import { drawerWidth } from './Sidebar';

interface IProps extends RouteComponentProps<{}> {
  drawerIsOpen: boolean;
  onToggleDrawer: (open: boolean) => void;
}

interface IState {
  anchorEl: HTMLElement | null;
}

class HeaderBase extends Component<IProps, IState> {
  state = {
    anchorEl: null
  };

  handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseAccountMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenDrawer = () => {
    this.props.onToggleDrawer(true);
  };

  handleSignOut = () => {
    auth0Client.signOut();
    this.props.history.push('/login');
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { drawerIsOpen } = this.props;

    const renderAccountMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={this.handleCloseAccountMenu}
      >
        <MenuItem>My Account</MenuItem>
        <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
      </Menu>
    );

    return (
      <Fragment>
        <StyledAppBar position="absolute" open={drawerIsOpen}>
          <StyledToolbar disableGutters={!drawerIsOpen}>
            <MenuButton
              aria-label="Open Drawer"
              open={drawerIsOpen}
              onClick={this.handleOpenDrawer}
            >
              <MenuIcon />
            </MenuButton>

            <Title component="h1" variant="h6" color="inherit" noWrap>
              <StyledLink to="/">Further Insights</StyledLink>
            </Title>
            {auth0Client.isAuthenticated() ? (
              <Fragment>
                <IconButton onClick={this.handleOpenAccountMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                {auth0Client.isAuthenticated() && renderAccountMenu}
              </Fragment>
            ) : (
              <Button>Sign In</Button>
            )}
          </StyledToolbar>
        </StyledAppBar>
      </Fragment>
    );
  }
}

const StyledToolbar = styled(Toolbar)`
  padding-right: 24px;
` as React.FC<ToolbarProps>;

interface IStyledAppBarProps extends AppBarProps {
  open: boolean;
}

const StyledAppBar = withTheme()(styled(AppBar)<IStyledAppBarProps>`
  && {
    ${props =>
      css`
        z-index: ${props.theme.zIndex.drawer + 1};
        transition: ${props.theme.transitions.create(['width', 'margin'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen
        })};
      `}
    ${props =>
      props.open &&
      css`
        margin-left: ${drawerWidth}px;
        width: ${`calc(100% - ${drawerWidth}px)`};
        transition: ${props.theme.transitions.create(['width', 'margin'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen
        })};
      `}
  }
`) as React.FC<IStyledAppBarProps>;

interface IMenuButtonProps extends IconButtonProps {
  open: boolean;
}

const MenuButton = styled(IconButton)<IMenuButtonProps>`
  && {
    margin-left: 12px;
    margin-right: 36px;
    display: ${props => props.open && 'none'};
  }
` as React.FC<IMenuButtonProps>;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
`;

const Title = withTheme()(styled(Typography)`
  flex-grow: 1;
`) as React.FC<TypographyProps>;

export const Header = withRouter(HeaderBase);
