import Button, { ButtonProps } from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper, { StepperProps } from '@material-ui/core/Stepper';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { Quiz } from '../../../models/Quiz';
import { Build } from './Build';
import { Deploy } from './Deploy';
import { Format } from './Format';
import { Results } from './Results';
import { Settings } from './Settings';

export interface IStepComponentProps extends RouteComponentProps {
  quiz: Quiz;
}

interface IStep {
  title: string;
  path: string;
  Component: React.ComponentType<IStepComponentProps>;
}

const steps: IStep[] = [
  { title: 'Campaign Type', path: 'settings', Component: Settings },
  { title: 'Choose Format', path: 'format', Component: Format },
  { title: 'Build', path: 'build', Component: Build },
  { title: 'Deploy', path: 'deploy', Component: Deploy },
  { title: 'Results', path: 'results', Component: Results }
];

interface IProps extends RouteComponentProps<{ action: string }> {
  quiz: Quiz;
}
interface IState {
  activeStep: number;
}

export class CampaignStepper extends React.Component<IProps, IState> {
  getActiveStepIndex = () => {
    return steps.findIndex(step => step.path === this.props.match.params.action);
  };

  handleNext = () => {
    const { history } = this.props;
    const nextIndex = this.getActiveStepIndex() + 1;
    const nextStep = steps[nextIndex];
    if (nextIndex === steps.length) {
      history.push('/campaigns');
    } else {
      history.push(this.pathForStep(nextStep));
    }
  };

  handleBack = () => {
    const { history } = this.props;
    const prevStep = steps[this.getActiveStepIndex() - 1];
    history.push(this.pathForStep(prevStep));
  };

  pathForStep = (step: IStep): string => {
    const {
      match: {
        url,
        params: { action }
      }
    } = this.props;
    return url.replace(action, step.path);
  };

  render() {
    const activeStep = this.getActiveStepIndex();
    const { quiz } = this.props;

    return (
      <Root>
        <StyledStepper activeStep={activeStep}>
          {steps.map(({ title }) => {
            const props = {};
            const labelProps = {};

            return (
              <Step key={title} {...props}>
                <StepLabel {...labelProps}>{title}</StepLabel>
              </Step>
            );
          })}
        </StyledStepper>
        <div>
          <div>
            {steps.map(step => (
              <Route
                key={step.path}
                path={this.pathForStep(step)}
                render={props => <step.Component {...props} quiz={quiz} />}
              />
            ))}
            <div>
              <StyledButton disabled={activeStep === 0} onClick={this.handleBack}>
                Back
              </StyledButton>

              <StyledButton variant="contained" color="primary" onClick={this.handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </StyledButton>
            </div>
          </div>
        </div>
      </Root>
    );
  }
}

const Root = styled.div`
  width: 100%;
`;

const StyledStepper = styled(Stepper)`
  background: none;
  margin-bottom: ${appTheme.spacing.unit * 6}px;
` as React.FC<StepperProps>;

const StyledButton = styled(Button)`
  margin-right: ${appTheme.spacing.unit};
` as React.FC<ButtonProps>;
