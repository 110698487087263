import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Typography
} from '@material-ui/core';
import { CollapseProps } from '@material-ui/core/Collapse';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { ListItemProps } from '@material-ui/core/ListItem';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import styled from '../../../../lib/styled-components';
import { extendedTheme } from '../../../../lib/theme';
import { Offer } from '../../../../models';
import { Quiz, EQuizFormat } from '../../../../models/Quiz';
import { ConfirmationDialog } from '../../../atoms/ConfirmationDialog';
import { ResultForm } from './ResultForm';
import { EOfferFormat } from '../../../../models/Offer';

export interface IProps extends RouteComponentProps {
  quiz: Quiz;
  refreshQuiz: () => void;
  handleFocusResult: (resultId: string) => void;
}

interface IState {
  openOfferIds: Set<string>;
  focusedOffer?: string;
  offerToDelete?: Offer;
}

export class ResultsEdit extends React.Component<IProps, IState> {
  state: IState = {
    openOfferIds: new Set<string>()
  };

  addingResult: boolean = false;

  toggleResult = (offerId: string) => {
    this.setState(
      prevState => {
        const { openOfferIds } = prevState;
        if (!openOfferIds.delete(offerId)) {
          openOfferIds.add(offerId);
        }
        return { openOfferIds };
      },
      () => this.handleFormFocus(offerId)
    );
  };

  addResult = async () => {
    const { quiz, refreshQuiz } = this.props;
    if (this.addingResult) {
      return;
    }
    this.addingResult = true;
    const format =
      quiz.format === EQuizFormat.GradedQuiz
        ? EOfferFormat.Motivational
        : EOfferFormat.PersonalityMotivational;

    const offer = new Offer({ format });
    quiz.offers.push(offer);
    await quiz.save({ with: 'offers' });
    this.toggleResult(offer.id);
    this.addingResult = false;
    refreshQuiz();
  };

  handleClickToggleResult = (offerId: string) => () => {
    this.toggleResult(offerId);
  };

  handleDeleteOffer = (offer: Offer) => () => {
    this.setState({ offerToDelete: offer });
  };

  handleConfirmDeleteOffer = async () => {
    const { offerToDelete } = this.state;
    const { quiz } = this.props;
    if (offerToDelete) {
      const offer = quiz.offers.find(offerItem => offerItem.id === offerToDelete.id);
      offer && (await offer.destroy());
    }
    this.setState({ offerToDelete: undefined });
  };

  handleCancelDeleteOffer = () => {
    this.setState({ offerToDelete: undefined });
  };

  handleFormFocus = (offerId: string) => {
    if (this.state.focusedOffer === offerId) {
      return;
    }
    this.props.handleFocusResult(offerId);
    this.setState({ focusedOffer: offerId });
  };

  public render() {
    const { quiz, refreshQuiz } = this.props;

    const { offerToDelete } = this.state;

    return (
      <React.Fragment>
        {offerToDelete && (
          <ConfirmationDialog
            open={true}
            title="Delete this result?"
            content={offerToDelete.outcome.text}
            onConfirm={this.handleConfirmDeleteOffer}
            onCancel={this.handleCancelDeleteOffer}
          />
        )}
        <List subheader={<ListSubheader component="div">Results</ListSubheader>}>
          {quiz.offers.map(offer => {
            const open = this.state.openOfferIds.has(offer.id);
            return (
              <OfferListItem key={`answer-form-${offer.id}`}>
                {!!!open && <LinkBlock onClick={this.handleClickToggleResult(offer.id)} />}

                <CollapseContainer>
                  <StyledCollapse in={!!!open}>
                    <Typography variant="body2">{offer.outcome.text}</Typography>
                  </StyledCollapse>
                  <StyledCollapse in={open} unmountOnExit>
                    <ResultForm
                      refreshQuiz={refreshQuiz}
                      quizFormat={quiz.format}
                      offer={offer}
                      onFormClick={this.handleFormFocus}
                      focused={offer.id === this.state.focusedOffer}
                    />
                  </StyledCollapse>
                </CollapseContainer>
                <ButtonsContainer>
                  <ExpansionButton onClick={this.handleClickToggleResult(offer.id)}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ExpansionButton>
                  {open && (
                    <IconButton onClick={this.handleDeleteOffer(offer)} title="Delete this offer">
                      <Delete />
                    </IconButton>
                  )}
                </ButtonsContainer>
              </OfferListItem>
            );
          })}
        </List>
        <Button variant="text" onClick={this.addResult}>
          Add Result
        </Button>
      </React.Fragment>
    );
  }
}

const OfferListItem = styled(ListItem)`
  cursor: pointer;
  align-items: start;
  border: 1px solid ${extendedTheme.borderColor};
  border-top: 0;
  &:first-of-type {
    border-top: 1px solid ${extendedTheme.borderColor};
  }
` as React.FC<ListItemProps>;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
`;

const LinkBlock = styled.a`
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
`;

const ExpansionButton = styled(IconButton)`` as React.FC<IconButtonProps>;

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  flex-grow: 1;
` as React.FC<CollapseProps>;
