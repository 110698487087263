import React from 'react';

import { Quiz } from '../../../models/Quiz';
import { IStepComponentProps } from './CampaignStepper';

interface IProps extends IStepComponentProps {
  quiz: Quiz;
}

export const Results: React.FC<IProps> = () => <React.Fragment>Results</React.Fragment>;
