import { Button, FormGroup, IconButton, Grid } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { Field, FieldArray, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import * as React from 'react';

import styled from '../../../../lib/styled-components';
import { appTheme } from '../../../../lib/theme';
import { Offer } from '../../../../models';
import { EOfferFormat, ILink } from '../../../../models/Offer';
import { EQuizFormat } from '../../../../models/Quiz';
import { AutoSave } from '../../../atoms/AutoSave';
import { ImagePicker } from '../../../atoms/ImagePicker';

export interface IProps {
  offer: Offer;
  focused: boolean;
  quizFormat: EQuizFormat;
  refreshQuiz: () => void;
  onFormClick: (offerId: string) => void;
}

interface IResultFormProps {
  format: string;
  range: {
    min: number;
    max: number;
  };
  outcome: {
    text: string;
    description: string;
    image: {
      original: string;
    };
  };
  links?: ILink[];
}

export class ResultForm extends React.Component<IProps> {
  saveResult = async (offerProps: IResultFormProps) => {
    const { offer, refreshQuiz, quizFormat } = this.props;
    offer.assignAttributes(offerProps);

    if (quizFormat === EQuizFormat.GradedQuiz) {
      if (offer.links.length > 0) {
        offer.format = EOfferFormat.Link;
      } else {
        offer.format = EOfferFormat.Motivational;
      }
    } else if (quizFormat === EQuizFormat.PersonalityQuiz) {
      if (offer.links.length > 0) {
        offer.format = EOfferFormat.PersonalityLink;
      } else {
        offer.format = EOfferFormat.PersonalityMotivational;
      }
    }

    await offer.save({ with: 'offer.id' });
    refreshQuiz();
  };

  public render() {
    const { offer, focused, quizFormat, onFormClick } = this.props;

    return (
      <React.Fragment>
        <Formik<IResultFormProps>
          initialValues={offer}
          onSubmit={() => {}}
          render={({ values, setFieldValue }) => (
            <StyledForm
              onSubmit={event => event.preventDefault()}
              onClick={() => onFormClick(offer.id)}
            >
              <Field type="hidden" name="outcome.image.original" />
              {(() => {
                switch (quizFormat) {
                  case EQuizFormat.GradedQuiz:
                    return (
                      <GradedQuizForm
                        values={values}
                        focused={focused}
                        setFieldValue={setFieldValue}
                      />
                    );
                  case EQuizFormat.PersonalityQuiz:
                    return (
                      <PersonalityQuizForm
                        values={values}
                        focused={focused}
                        setFieldValue={setFieldValue}
                      />
                    );

                  default:
                    return null;
                }
              })()}
              <AutoSave onSubmit={this.saveResult} values={values} />
            </StyledForm>
          )}
        />
      </React.Fragment>
    );
  }
}

const StyledForm = styled.form`
  cursor: default;
`;

interface IResultSubFormProps {
  values: IResultFormProps;
  focused: boolean;
  setFieldValue: any;
}

const GradedQuizForm = ({ values, focused }: IResultSubFormProps) => {
  return (
    <React.Fragment>
      <Field
        component={TextField}
        name="outcome.text"
        variant="outlined"
        margin="normal"
        type="text"
        label="Text"
        fullWidth
        autoFocus={focused}
      />
      <FormGroup row>
        <StyledField
          component={TextField}
          name="range.min"
          variant="outlined"
          type="number"
          margin="normal"
          label="Min Score"
        />
        <StyledField
          component={TextField}
          name="range.max"
          variant="outlined"
          type="number"
          margin="normal"
          label="Max Score"
        />
      </FormGroup>
      <ResultLinks values={values} />
    </React.Fragment>
  );
};

const StyledField = styled(Field)`
  margin-right: ${appTheme.spacing.unit}px;
`;

const PersonalityQuizForm = ({ values, focused, setFieldValue }: IResultSubFormProps) => {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={4}>
          <ImagePicker
            imageUrl={values.outcome.image.original}
            imagePreviewOptions={{ width: 200, height: 200 }}
            onSuccess={response => {
              setFieldValue('outcome.image.original', response.url);
            }}
          />
        </Grid>
        <Grid item sm={8}>
          <Field
            component={TextField}
            name="outcome.text"
            variant="outlined"
            margin="normal"
            type="text"
            label="Text"
            autoFocus={focused}
            fullWidth
          />
          <Field
            component={TextField}
            multiline
            name="outcome.description"
            variant="outlined"
            margin="normal"
            type="text"
            label="Description"
            fullWidth
          />
        </Grid>
      </Grid>
      <ResultLinks values={values} />
    </React.Fragment>
  );
};

interface IResultLinkProps {
  values: IResultFormProps;
}

const ResultLinks = ({ values }: IResultLinkProps) => (
  <FieldArray
    name="links"
    render={arrayHelpers => (
      <React.Fragment>
        {values.links &&
          values.links.map((link, index) => (
            <LinkRow key={`link-${index}`}>
              <LinkTextInput
                name={`links.${index}.text`}
                label="Button Text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <LinkTextInput
                name={`links.${index}.src`}
                label="Link URL"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <StyledField
                name={`links.${index}.new_tab`}
                component={CheckboxWithLabel}
                margin="normal"
                Label={{ label: 'New Tab' }}
              />
              <IconButton onClick={() => arrayHelpers.remove(index)}>
                <RemoveCircle />
              </IconButton>
            </LinkRow>
          ))}
        <Button
          variant="text"
          onClick={() => arrayHelpers.push({ text: '', src: '', new_tab: true })}
        >
          Add Link
        </Button>
      </React.Fragment>
    )}
  />
);

const LinkTextInput = styled(StyledField)`
  flex-grow: 1;
`;

const LinkRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
