import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { auth0Client } from '../../lib/Auth';
import { RouteComponentProps } from 'react-router-dom';

class CallbackBase extends Component<RouteComponentProps> {
  async componentDidMount() {
    const redirectTo = await auth0Client.handleCallbackAuthentication();
    this.props.history.replace(redirectTo);
  }

  render() {
    return <p>Loading profile...</p>;
  }
}

export const Callback = withRouter(CallbackBase);
