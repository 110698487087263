import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { Quiz, EQuizFormat } from '../../../models/Quiz';

interface IProps extends RouteComponentProps {
  quiz: Quiz;
}

export class Format extends React.PureComponent<IProps> {
  handlePickFormat = (format: EQuizFormat) => async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { quiz, history, match } = this.props;

    quiz.format = format;

    await quiz.save();
    history.push(match.url.replace('format', 'build'));
  };

  render() {
    return (
      <React.Fragment>
        <Title variant="h4" align="center">
          What format would you like to use?
        </Title>
        <Container>
          <Tile variant="contained" onClick={this.handlePickFormat(EQuizFormat.GradedQuiz)}>
            Graded Quiz
          </Tile>
          <Tile variant="contained" onClick={this.handlePickFormat(EQuizFormat.PersonalityQuiz)}>
            Personality Quiz
          </Tile>
        </Container>
      </React.Fragment>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${appTheme.breakpoints.down('sm')} {
    display: inherit;
  }
`;

const Title = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 8}px;
` as React.FC<TypographyProps>;

const Tile = styled(Button)`
  background: ${appTheme.palette.common.white};
  height: 170px;
  width: 170px;
  margin: ${appTheme.spacing.unit * 4}px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${appTheme.breakpoints.down('sm')} {
    height: inherit;
    display: inherit;
  }
` as React.FC<ButtonProps>;
