import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: { fontSize: "4rem" },
    h2: { fontSize: "2.57rem" },
    h3: { fontSize: "2.2857rem" },
    h4: {
      fontSize: "1.71rem"
    }
  },
  palette: {
    primary: {
      main: "#6141FF"
    },
    text: {
      primary: "rgba(11, 9, 22, 0.5)"
    }
  },
  shape: {
    borderRadius: 10
  }
});

export const extendedTheme = {
  borderColor: "#ddd"
};

export { theme as appTheme };
