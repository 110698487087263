import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
  withTheme
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { TypographyProps } from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Field, Form, FormikProps } from 'formik';
import { fieldToTextField, TextField, TextFieldProps } from 'formik-material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { AuthLayout } from '../Layouts/AuthLayout';

export interface ISignUpFormValues {
  email: string;
  password: string;
  company: string;
  name: string;
}

class PasswordField extends React.Component<TextFieldProps, { showPassword: boolean }> {
  state = {
    showPassword: false
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    return (
      <MuiTextField
        {...fieldToTextField(this.props)}
        type={this.state.showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

const SignUpForm = ({ isSubmitting, status }: FormikProps<ISignUpFormValues>) => (
  <AuthLayout bottomElement={alreadyHaveAnAccount}>
    <SignUpTitle variant="h4">Sign Up</SignUpTitle>
    <Form>
      <Field
        component={TextField}
        focus="true"
        name="name"
        variant="outlined"
        label="name"
        fullWidth
        margin="normal"
      />
      <Field
        component={TextField}
        name="company"
        variant="outlined"
        label="company"
        fullWidth
        margin="normal"
      />
      <Field
        component={TextField}
        type="email"
        name="email"
        variant="outlined"
        label="email"
        fullWidth
        margin="normal"
      />
      <Field
        component={PasswordField}
        type="password"
        name="password"
        variant="outlined"
        label="password"
        fullWidth
        margin="normal"
      />
      {status && <FormHelperText>{status}</FormHelperText>}
      <SignUpButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
        Sign Up
      </SignUpButton>
    </Form>
  </AuthLayout>
);

const SignUpTitle = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 3}px;
` as React.FC<TypographyProps>;

const SignUpButton = styled(Button)`
  margin: ${appTheme.spacing.unit * 3}px auto 0;
  width: 75%;
  display: flex;
` as React.FC<ButtonProps>;

const SubButton = styled(Button)`
  display: flex;
  margin: ${appTheme.spacing.unit}px auto;
` as React.FC<ButtonProps>;

const MyLink = (props: any) => <Link to="/login" {...props} />; //eslint-disable-line

const alreadyHaveAnAccount = (
  <SubButton component={MyLink}>Already have an account? Log in here</SubButton>
);

const themed = withTheme()(SignUpForm);
export { themed as SignUpForm };
