import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import * as React from 'react';

import { QuizContext } from '../../../../contexts/QuizContext';
import { Offer } from '../../../../models';
import { Answer } from '../../../../models/Answer';
import { EQuizFormat } from '../../../../models/Quiz';
import { AutoSave } from '../../../atoms/AutoSave';

export interface IProps {
  answer: Answer;
  outcomes: Offer[];
  focused: boolean;
  quizFormat: EQuizFormat;
  refreshQuiz: () => void;
}

interface IAnswerFormProps {
  id: string;
  position: number;
  points?: number;
  isCorrect: boolean;
  text: string;
  outcomeId: string;
}

export class AnswerForm extends React.PureComponent<IProps> {
  saveAnswer = async ({ outcomeId, ...answerProps }: IAnswerFormProps) => {
    const { answer, outcomes, refreshQuiz } = this.props;
    const outcome = outcomes.find(o => o.id === outcomeId);
    if (outcome) {
      answer.offer = outcome;
    }
    answer.assignAttributes(answerProps);
    await answer.save({ with: 'offer.id' });
    refreshQuiz();
  };

  public render() {
    const { answer, focused, quizFormat } = this.props;

    const { text = '', rest } = answer.attributes;
    const initialValues: IAnswerFormProps = {
      text: text || '',
      ...rest,
      outcomeId: answer.offer ? answer.offer.id : ''
    };

    return (
      <React.Fragment>
        <Formik<IAnswerFormProps>
          initialValues={initialValues}
          onSubmit={() => {}}
          render={({ values, handleChange }) => (
            <form onSubmit={event => event.preventDefault()}>
              <Field
                component={TextField}
                name="text"
                variant="outlined"
                margin="dense"
                type="text"
                label="Answer"
                fullWidth
                autoFocus={focused}
              />
              {(() => {
                switch (quizFormat) {
                  case EQuizFormat.GradedQuiz:
                    return <GradedQuizForm />;
                  case EQuizFormat.PersonalityQuiz:
                    return <PersonalityQuizForm onChange={handleChange} values={values} />;

                  default:
                    return null;
                }
              })()}

              <AutoSave values={values} onSubmit={this.saveAnswer} />
            </form>
          )}
        />
      </React.Fragment>
    );
  }
}

interface IPersonalityQuizFormProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  values: IAnswerFormProps;
}

const GradedQuizForm = () => {
  return <Field component={CheckboxWithLabel} name="isCorrect" Label={{ label: 'Correct?' }} />;
};

const PersonalityQuizForm = ({ onChange, values }: IPersonalityQuizFormProps) => {
  return (
    <QuizContext.Consumer>
      {quizContext => (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel htmlFor={`answer-outcome-${values.id}`}>Outcome</InputLabel>
          <Select
            value={values.outcomeId}
            onChange={onChange}
            inputProps={{ name: 'outcomeId' }}
            variant="outlined"
            name="outcomeId"
            input={
              <OutlinedInput labelWidth={70} name="outcomeId" id={`answer-outcome-${values.id}`} />
            }
          >
            {quizContext.getOutcomes().map(offer => (
              <MenuItem value={offer.id} key={`answer-offer-${offer.id}`}>
                {offer.outcome.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </QuizContext.Consumer>
  );
};
