import { Grid, List, Paper, ListItemText, Button } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { GridProps } from '@material-ui/core/Grid';
import { ListProps } from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem/ListItem';
import { PaperProps } from '@material-ui/core/Paper';
import React from 'react';
import { LinkProps, Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { QuizContext } from '../../../../contexts/QuizContext';
import { appTheme, extendedTheme } from '../../../../lib/theme';
import { Quiz } from '../../../../models/Quiz';
import { ListItemLink } from '../../../atoms/ListItemLink';
import { QuestionEdit } from './QuestionEdit';
import { QuizPreview } from './QuizPreview';
import { ResultsEdit } from './ResultsEdit';
import { WelcomeEdit } from './WelcomeEdit';
import { Question } from '../../../../models';
import styled from '../../../../lib/styled-components';
import { StyledListItemLink } from './atoms/StyledListItemLink';
import { QuestionsList } from './QuestionsList';

interface IProps extends RouteComponentProps {
  quiz: Quiz;
}

interface IState {
  selectedResultId: string;
}

export class Build extends React.Component<IProps, IState> {
  state: IState = {
    selectedResultId: ''
  };

  questionForId = (id: string) => {
    return this.props.quiz.questions.find(question => question.id === id);
  };

  handleAddQuestion = async () => {
    const { quiz, history, match } = this.props;
    const question = new Question({ position: quiz.questions.length + 1 });
    quiz.questions.push(question);
    await quiz.save({ with: 'questions' });
    history.push(`${match.url}/questions/${question.id}`);
  };

  handleFocusResult = (resultId: string) => {
    this.setState({ selectedResultId: resultId });
  };

  render() {
    const { quiz, match } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item sm={7}>
          <EditorPaper>
            <QuizContext.Consumer>
              {({ refreshQuiz }) => (
                <Grid container>
                  <Grid item sm={3}>
                    <StyledList>
                      <WelcomeLink to={`${match.url}/welcome`}>Welcome</WelcomeLink>
                      <QuestionsList quiz={quiz} baseUrl={match.url} refreshQuiz={refreshQuiz} />
                      <ListItem>
                        <Button fullWidth onClick={this.handleAddQuestion}>
                          Add Question
                        </Button>
                      </ListItem>
                      <ResultsLink to={`${match.url}/results`}>Results</ResultsLink>
                    </StyledList>
                  </Grid>
                  <EditFormGrid item sm={9}>
                    <React.Fragment>
                      <Route
                        exact
                        path={`${match.url}`}
                        render={() => {
                          if (quiz.offers.length < 1) {
                            return <Redirect to={`${match.url}/results`} />;
                          } else {
                            return <Redirect to={`${match.url}/welcome`} />;
                          }
                        }}
                      />
                      <Route
                        path={`${match.url}/welcome`}
                        exact
                        render={props => (
                          <WelcomeEdit quiz={quiz} {...props} refreshQuiz={refreshQuiz} />
                        )}
                      />
                      <Route
                        path={`${match.url}/questions/:questionId`}
                        render={props => {
                          const question = this.questionForId(props.match.params.questionId);
                          if (question) {
                            return (
                              <QuestionEdit
                                refreshQuiz={refreshQuiz}
                                question={question}
                                {...props}
                                key={`question-edit-${question.id}`}
                              />
                            );
                          } else {
                            return <Redirect to={`${match.url}`} />;
                          }
                        }}
                      />
                      <Route
                        exact
                        path={`${match.url}/results`}
                        render={props => (
                          <ResultsEdit
                            quiz={quiz}
                            refreshQuiz={refreshQuiz}
                            handleFocusResult={this.handleFocusResult}
                            {...props}
                          />
                        )}
                      />
                    </React.Fragment>
                  </EditFormGrid>
                </Grid>
              )}
            </QuizContext.Consumer>
          </EditorPaper>
        </Grid>
        <Grid item sm={5}>
          <PreviewPaper>
            <Route
              path={`${match.url}/:action/:id?`}
              render={({ match }: RouteComponentProps<{ action: string; id: string }>) => {
                let path: string;
                switch (match.params.action) {
                  case 'welcome':
                    path = '/';
                    break;
                  case 'questions':
                    const question = this.questionForId(match.params.id);
                    path = question ? `/questions/${question.position}` : '/';
                    break;
                  case 'leadgen':
                    path = 'leadgen';
                    break;
                  case 'results':
                    path = 'result';
                    break;
                  default:
                    path = '/';
                }
                return (
                  <PreviewContainer>
                    <QuizPreview
                      quiz={quiz}
                      path={path}
                      selectedResultId={this.state.selectedResultId}
                    />
                  </PreviewContainer>
                );
              }}
            />
          </PreviewPaper>
        </Grid>
      </Grid>
    );
  }
}

const EditFormGrid = styled(Grid)`
  padding: ${appTheme.spacing.unit * 3}px;
` as React.FC<GridProps>;

const EditorPaper = styled(Paper)`` as React.FC<PaperProps>;

const PreviewPaper = styled(Paper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${appTheme.spacing.unit * 5}px;
` as React.FC<PaperProps>;

const PreviewContainer = styled.div`
  flex: 1 1 auto;
  max-width: 728px;
`;

const WelcomeLink = styled(StyledListItemLink)`
  a {
    border-top-left-radius: ${appTheme.shape.borderRadius}px;
    text-align: center;
  }
`;

const ResultsLink = styled(StyledListItemLink)`
  border-bottom: none;
  border-top: 1px solid ${extendedTheme.borderColor};
  position: absolute;
  bottom: 0;
  width: 100%;
  a {
    text-align: center;
    border-bottom-left-radius: ${appTheme.shape.borderRadius}px;
  }
`;

const StyledList = styled(List)`
  border-right: 1px solid ${extendedTheme.borderColor};
  height: 100%;
  min-height: 70vh;
  padding: 0;
` as React.FC<ListProps>;
