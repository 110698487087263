import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withTheme
} from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'styled-components';

import styled from '../../lib/styled-components';

interface IProps {
  drawerIsOpen: boolean;
  onToggleDrawer: (open: boolean) => void;
}

export class Sidebar extends Component<IProps> {
  handleDrawerClose = () => {
    this.props.onToggleDrawer(false);
  };

  render() {
    return (
      <StyledDrawer variant="permanent" open={this.props.drawerIsOpen}>
        <ToolbarIcon>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </ToolbarIcon>
        <Divider />
        <List>{mainListItems}</List>
      </StyledDrawer>
    );
  }
}

const mainListItems = (
  <Fragment>
    <Link to="/campaigns">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Campaigns" />
      </ListItem>
    </Link>
  </Fragment>
);

export const drawerWidth = 240;

const ToolbarIcon = withTheme()(styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  ${props => props.theme.mixins.toolbar};
`);

interface IStyledDrawerProps extends DrawerProps {
  open: boolean;
}

const StyledDrawer = withTheme()(styled(Drawer).attrs({
  classes: { paper: 'DrawerStyles' }
})<IStyledDrawerProps>`
  & .DrawerStyles {
    ${props =>
      css`
        overflow-x: hidden;
        position: relative;
        white-space: nowrap;
        width: ${drawerWidth}px;
        transition: ${props.theme.transitions.create('width', {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen
        })};
      `}
    ${props =>
      !props.open &&
      css`
        transition: ${props.theme.transitions.create('width', {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen
        })};
        width: ${props.theme.spacing.unit * 7}px;
        ${props.theme.breakpoints.up('sm')} {
          width: ${props.theme.spacing.unit * 9}px;
        }
      `}
  }
`) as React.FC<IStyledDrawerProps>;
