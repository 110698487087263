import React, { Component } from 'react';

import { AppState } from '../../contexts/AppState';

interface IState {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

class Provider extends Component<{}, IState> {
  state = {
    drawerOpen: false,
    toggleDrawer: () => {
      this.setState(state => ({
        drawerOpen: !state.drawerOpen
      }));
    }
  };

  // toggleDrawer: () => void;
  // constructor(props: {}) {
  //   super(props);

  //   this.toggleDrawer = () => {
  //     this.setState(state => ({
  //       drawerOpen: !state.drawerOpen
  //     }));
  //   };

  //   this.state = {
  //     drawerOpen: false,
  //     toggleDrawer: this.toggleDrawer
  //   };
  // }

  render() {
    return <AppState.Provider value={this.state}>{this.props.children}</AppState.Provider>;
  }
}
export default Provider;
