import * as React from 'react';
import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { Paper } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { ReactComponent as Logo } from '../../../components/atoms/logo-h-black.svg';
interface IProps {
  children: React.ReactNode;
  bottomElement: React.ReactNode;
}

export const AuthLayout: React.SFC<IProps> = ({ children, bottomElement }) => {
  return (
    <Main>
      <StyledLogo />
      <Outline elevation={1}>{children}</Outline>
      {bottomElement}
    </Main>
  );
};

const StyledLogo = styled(Logo)`
  width: 70%;
  margin: ${appTheme.spacing.unit * 6}px auto 0;
  display: block;
`;

const Main = styled.main`
  width: auto;
  display: block;
  margin-left: ${appTheme.spacing.unit * 3}px;
  margin-right: ${appTheme.spacing.unit * 3}px;
  ${appTheme.breakpoints.up(400 + appTheme.spacing.unit * 3 * 2)} {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Outline = styled(Paper)`
  margin-top: ${appTheme.spacing.unit * 4}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${`${appTheme.spacing.unit * 5}px ${appTheme.spacing.unit * 3}px ${appTheme.spacing
    .unit * 5}px`};
` as React.ComponentType<PaperProps>;
