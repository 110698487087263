import * as filepicker from 'filepicker-js';
import React from 'react';
import styled from '../../lib/styled-components';
import { appTheme, extendedTheme } from '../../lib/theme';
import { Image } from '@material-ui/icons';
const { REACT_APP_FILESTACK_API_KEY = '' } = process.env;

export interface IFilepickerOptions {
  buttonClass?: string;
  buttonText?: string;
  container?: string;
  conversions?: string[];
  cropForce?: boolean;
  cropMax?: [number, number];
  cropMin?: [number, number];
  cropRatio?: number;
  mimetypes?: string;
  services?: string[];
}

export interface IPreviewStyle {
  height?: number;
  width?: number;
  display?: string;
}

const DEFAULT_FILEPICKER_OPTIONS: IFilepickerOptions = {
  buttonClass: 'btn btn-primary',
  buttonText: 'Upload Image',
  container: 'modal',
  conversions: ['crop', 'rotate', 'filter'],
  cropForce: true,
  cropMax: [1200, 1200],
  cropMin: [100, 100],
  cropRatio: 1,
  mimetypes: 'image/*',
  services: ['CONVERT', 'COMPUTER', 'IMAGESEARCH']
};

const DEFAULT_PREVIEW_STYLE: IPreviewStyle = {
  height: 200,
  width: 200,
  display: 'block'
};

const processedUrl = (imageUrl: string) => {
  const processBaseUrl = 'https://process.filestackapi.com';
  const outputTransform = 'output=compress:true,format:jpg,quality:50';
  return `${processBaseUrl}/${outputTransform}/${imageUrl}`;
};

interface IProps {
  //@ts-ignore
  onSuccess: (result) => void;
  filepickerOptions?: IFilepickerOptions;
  imagePreviewOptions?: IPreviewStyle;
  imageUrl?: string;
}

export class ImagePicker extends React.Component<IProps> {
  initClient = async () => {
    filepicker.setKey(REACT_APP_FILESTACK_API_KEY);
    filepicker.pick(this.filepickerOptions(), this.props.onSuccess);
  };

  filepickerOptions = () => {
    return {
      ...DEFAULT_FILEPICKER_OPTIONS,
      ...this.props.filepickerOptions
    };
  };

  previewOptions = (): IPreviewStyle => {
    return { ...DEFAULT_PREVIEW_STYLE, ...this.props.imagePreviewOptions };
  };

  render() {
    return (
      <Container previewProps={this.previewOptions()} onClick={this.initClient}>
        {this.props.imageUrl ? (
          <ImagePreview
            src={processedUrl(this.props.imageUrl)}
            previewProps={this.previewOptions()}
          />
        ) : (
          <Image />
        )}
      </Container>
    );
  }
}

const Container = styled.div<{ previewProps: IPreviewStyle }>`
  display: flex;
  margin: ${appTheme.spacing.unit * 2}px 0 ${appTheme.spacing.unit}px;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${props => props.previewProps.height}px;
  width: ${props => props.previewProps.width}px;
  border: 5px dashed ${extendedTheme.borderColor};
  cursor: pointer;
`;

const ImagePreview = styled.img<{ previewProps: IPreviewStyle }>`
  width: ${props => (props.previewProps.width as number) - 10}px;
  height: ${props => (props.previewProps.height as number) - 10}px;
`;
