import { ListItemIcon, ListItemText } from '@material-ui/core';
import { ListItemIconProps } from '@material-ui/core/ListItemIcon';
import { ListItemTextProps } from '@material-ui/core/ListItemText';
import { DragHandle } from '@material-ui/icons';
import React from 'react';
import { DragDropContext, Draggable, DragUpdate, Droppable } from 'react-beautiful-dnd';

import styled from '../../../../lib/styled-components';
import { Question, Quiz } from '../../../../models';
import { StyledListItemLink } from './atoms/StyledListItemLink';

interface IProps {
  quiz: Quiz;
  baseUrl: string;
  refreshQuiz: () => void;
}

interface IState {
  questions: Question[];
  disableDragging: boolean;
}

const reorder = (list: Question[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export class QuestionsList extends React.Component<IProps, IState> {
  state: IState = {
    questions: this.props.quiz.questions,
    disableDragging: false
  };

  onDragEnd = async (result: DragUpdate) => {
    const { quiz, refreshQuiz } = this.props;
    if (!result.destination) {
      return;
    }
    this.setState({ disableDragging: true });
    const questions = reorder(this.state.questions, result.source.index, result.destination.index);

    questions.forEach((question, index) => (question.position = index + 1));

    this.setState({ questions });
    quiz.questions = questions;
    await quiz.save({ with: 'questions' });
    refreshQuiz();
    this.setState({ disableDragging: false });
  };

  render() {
    const { baseUrl } = this.props;
    const { questions, disableDragging } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                  isDragDisabled={disableDragging}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StyledListItemLink
                        key={`question-${question.id}`}
                        to={`${baseUrl}/questions/${question.id}`}
                      >
                        <QuestionTitle title={question.prompt}>
                          {`${question.position}. ${question.prompt}`}
                        </QuestionTitle>
                        <QuestionDragIcon>
                          <DragHandle />
                        </QuestionDragIcon>
                      </StyledListItemLink>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

const QuestionTitle = styled(ListItemText)`
  overflow: hidden;
  text-overflow: ellipsis;
` as React.FC<ListItemTextProps>;

const QuestionDragIcon = styled(ListItemIcon)`
  cursor: grab;
  margin-right: 0;
` as React.FC<ListItemIconProps>;

// {
//    <StyledListItemLink
//   key={`question-${question.id}`}
//   component="div"
//   to={`${baseUrl}/questions/${question.id}`}
// >
//   <ListItemText title={question.prompt}>
//     {`${question.position}. ${question.prompt}`}
//   </ListItemText>
// </StyledListItemLink>
// }
