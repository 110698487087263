import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { IStepComponentProps } from './CampaignStepper';

export class Settings extends React.PureComponent<IStepComponentProps> {
  handlePickType = (hasLeadGeneration: boolean) => async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { quiz, history, match } = this.props;

    if (hasLeadGeneration) {
      quiz.requireLeadGenerationForResults = true;
    } else {
      quiz.requireLeadGenerationForResults = false;
    }
    await quiz.save();
    history.push(match.url.replace('settings', 'format'));
  };

  render() {
    return (
      <React.Fragment>
        <Title variant="h4" align="center">
          Pick Your Campaign Goal
        </Title>
        <Container>
          <TileButton variant="contained" onClick={this.handlePickType(false)}>
            Conversion
          </TileButton>
          {/* <TileButton variant="contained" onClick={this.handlePickType(true)}>
            Lead Generation
          </TileButton> */}
        </Container>
      </React.Fragment>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${appTheme.breakpoints.down('sm')} {
    display: inherit;
  }
`;

const Title = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 8}px;
` as React.FC<TypographyProps>;

const TileButton = styled(Button)`
  background: ${appTheme.palette.common.white};
  height: 170px;
  width: 170px;
  margin: ${appTheme.spacing.unit * 4}px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${appTheme.breakpoints.down('sm')} {
    height: inherit;
    display: inherit;
  }
` as React.FC<ButtonProps>;
