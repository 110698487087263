import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Quiz } from '../../../../models/Quiz';
import { AutoSave } from '../../../atoms/AutoSave';
import { ImagePicker } from '../../../atoms/ImagePicker';

export interface IProps extends RouteComponentProps {
  quiz: Quiz;
  refreshQuiz: () => void;
}

interface IWelcomeEditFormValues {
  description: string;
  title: string;
  notes: string;
  image: { original: string };
}

export class WelcomeEdit extends React.Component<IProps> {
  saveQuiz = async (props: IWelcomeEditFormValues) => {
    const { quiz, refreshQuiz } = this.props;
    quiz.assignAttributes(props);
    await quiz.save();
    refreshQuiz();
  };

  render() {
    const { quiz } = this.props;

    return (
      <React.Fragment>
        <Formik<IWelcomeEditFormValues>
          initialValues={quiz.attributes as IWelcomeEditFormValues}
          onSubmit={() => {}}
          render={({ values, setFieldValue }) => (
            <form onSubmit={event => event.preventDefault()}>
              <Field type="hidden" name="image.original" />
              <ImagePicker
                imageUrl={values.image.original}
                imagePreviewOptions={{ width: 400, height: 200 }}
                filepickerOptions={{ cropMax: [2400, 1200], cropMin: [200, 100], cropRatio: 2 }}
                onSuccess={response => {
                  setFieldValue('image.original', response.url);
                }}
              />
              <Field
                component={TextField}
                name="title"
                variant="outlined"
                type="text"
                label="Title"
                margin="normal"
                fullWidth
              />
              <Field
                component={TextField}
                name="description"
                variant="outlined"
                type="text"
                label="Description"
                margin="normal"
                fullWidth
              />
              <Field
                component={TextField}
                name="notes"
                variant="outlined"
                type="text"
                label="Notes"
                margin="normal"
                fullWidth
              />
              <AutoSave onSubmit={this.saveQuiz} values={values} />
            </form>
          )}
        />
      </React.Fragment>
    );
  }
}
