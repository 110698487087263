import { CssBaseline, Grid, MuiThemeProvider } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Callback } from './components/molecules/Callback';
import { SecuredRoute } from './components/molecules/SecuredRoute';
import Provider from './components/organisms/Provider';
import { LogIn } from './components/templates/Authentication/LogIn';
import { SignUp } from './components/templates/Authentication/SignUp';
import { Campaigns } from './components/templates/Campaigns';
import { Layout } from './components/templates/Layouts/SecuredLayout';
import { appTheme } from './lib/theme';

class App extends Component {
  render() {
    return (
      <Provider>
        <MuiThemeProvider theme={appTheme}>
          <BrowserRouter>
            <Fragment>
              <CssBaseline />
              <Switch>
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/signup" component={SignUp} />
                <Route path="/">
                  <Layout>
                    <Grid container>
                      <Grid item xs={12}>
                        <Switch>
                          <SecuredRoute
                            exact
                            path="/"
                            render={() => {
                              return <Redirect to="/campaigns" />;
                            }}
                          />
                          <Route exact path="/auth/callback" component={Callback} />
                          <SecuredRoute path="/campaigns" component={Campaigns} />
                        </Switch>
                      </Grid>
                    </Grid>
                  </Layout>
                </Route>
              </Switch>
            </Fragment>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
